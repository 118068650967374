<div class="center-content-vertically full-height">
  <div class="header">
    <h1>{{sliceData.title}}</h1>
  </div>
  <div class="page-intro center" *ngIf="showContent">
    <div class="steps">
      <span>1</span> <span> - </span><span>2</span><span> - </span><span class="active">3</span>
    </div>
<!--    <ng-lottie [options]="lottieConfig" [width]="lottieSize" [height]="lottieSize" [styles]="styles"></ng-lottie>-->
    <div class="img-fluid" *ngIf="sliceData.image && isSuccessPage()">
      <br>
      <img [src]="sliceData.image" [style]="{width: lottieSize}"/>
    </div>

    <div [@content]>
      <div class="card-container">
        <div class="">
          <div class="flex col content" [innerHtml]="content">
          </div>
        </div>
      </div>
      <div class="flex col content" [innerHtml]="sliceData.appDownloadText"></div>
      <div *ngIf="sliceData.showAppDownloadButtons" class="app-store-buttons flex row center vcenter wrap">
        <a (click)="openAppStore('google')"><img [src]="'/assets/google-play-badge-' + currentLang + '.png'"
            class="google" /></a>
        <a (click)="openAppStore('apple')"><img [src]="'/assets/App_Store_Badge_de.svg'" class="apple" /></a>
      </div>
    </div>
  </div>
</div>
