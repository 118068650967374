import { Component, OnInit, Input } from '@angular/core';
import { OpeningHoursService } from '../opening-hours.service';
import { TrackingService } from "../tracking.service";

@Component({
  selector: 'app-call-us',
  templateUrl: './call-us.component.html',
  styleUrls: ['./call-us.component.scss'],
})
export class CallUsComponent implements OnInit {

  @Input() light: boolean;
  isOpen: boolean;

  constructor(private openingHours: OpeningHoursService, public trackingService: TrackingService) {}

  ngOnInit() {
    this.isOpen = this.openingHours.isHotlineOpen(new Date());
  }
}
