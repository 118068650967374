import { Component, OnInit, Input } from '@angular/core';
import { TrackingService } from '../tracking.service';
import { StorageService } from '../storage.service';
import { Router } from '@angular/router';
import { StorageEntryConfig } from '../storage-entry-config.class';
import { ResizeService } from '../resize.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss']
})
export class DownloadButtonComponent implements OnInit {
  @Input() buttonExtraClass: string;
  @Input() mobileCtaText: string;
  @Input() desktopCtaText: string;
  isDesktop;
  is20Min = false;

  constructor(
    public tracking: TrackingService,
    private storage: StorageService,
    private router: Router,
    private resizeService: ResizeService
  ) {}

  ngOnInit() {
    this.is20Min = this.storage.get(StorageEntryConfig.UTM_CAMPAIGN) == '20min';
    this.resizeService.data.subscribe(data => {
      this.isDesktop = data.isDesktop;
    });
  }

  openAppStore() {
    this.tracking.ctaClick('download link');
    setTimeout(() => {
      if (this.is20Min) {
        // 20minutes wants different links depending on where the button is
        if (this.router.url.includes('pre-order/success')) {
          window.location.href =
            'https://app.adjust.com/sil439h?campaign=landingpage&adgroup=swype&creative=cta2';
        } else if (this.router.url.includes('pre-order')) {
          window.location.href =
            'https://app.adjust.com/sil439h?campaign=landingpage&adgroup=swype&creative=cta1';
        } else {
          window.location.href =
            'https://app.adjust.com/sil439h?campaign=landingpage&adgroup=swype&creative=cta';
        }
      } else {
        window.location.href = this.tracking.getDownloadLink();
      }
    }, 250);
  }
}
