<div class="footer">
  <div class="footer-content">
    <div>
      <span>© {{currentYear}} - {{'COMPANYNAME' | translate}}</span>
      <div class="legal-links">
        <a href="/assets/pdfs/{{'TERMSPDFNAME' | translate}}.pdf" target="_blank">{{'TERMSLINK' | translate}}</a>
        <a routerLink="/{{lang.current}}/imprint">{{'IMPRINTLINK' | translate}}</a>
        <a routerLink="/{{lang.current}}/data-protection">{{'DATAPROTECTIONLINK' | translate}}</a>
      </div>
    </div>
    <app-call-us></app-call-us>
    <div>
      <app-social-icons [iconType]="''"></app-social-icons>
    </div>
  </div>
</div>
