import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { CustomContentSlice } from '../custom-content-slice';

@Component({
  selector: 'app-custom-content',
  templateUrl: './custom-content.component.html',
  styleUrls: ['./custom-content.component.scss'],
  encapsulation: ViewEncapsulation.None //to be able to style content inserted via innerHTML
})
export class CustomContentComponent implements OnInit {
  @Input() sliceData: CustomContentSlice;

  constructor() {}

  ngOnInit(): void {}
}
