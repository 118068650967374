import { Component, OnInit, Input } from '@angular/core';
import { ImageWithCtaSlice } from '../image-with-cta-slice';

@Component({
  selector: 'app-image-with-cta',
  templateUrl: './image-with-cta.component.html',
  styleUrls: ['./image-with-cta.component.scss']
})
export class ImageWithCTAComponent implements OnInit {
  @Input() sliceData: ImageWithCtaSlice;

  constructor() {}

  ngOnInit() {}
}
