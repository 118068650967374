import { Component } from '@angular/core';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent {
  currentYear = new Date().getFullYear()
  constructor(public lang: LanguageService) { }

}
