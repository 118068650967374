import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { LanguageService } from '../language.service';
import { Subscription } from 'rxjs';
import { VideoSlice } from '../video-slice';
import { ResizeService } from '../resize.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent {
  @Input() sliceData: VideoSlice;
  @ViewChild('video') video: ElementRef;
  sub: Subscription;
  videoStarted = false;
  isDesktop: boolean;

  constructor(
    public lang: LanguageService,
    private resizeService: ResizeService
  ) {
    this.resizeService.data.subscribe(data => {
      this.isDesktop = data.isDesktop;
    });
  }

  playVideo() {
    if (!this.videoStarted) {
      let video = this.video.nativeElement;
      video.muted = true;
      video.playsinline = true;
      video.loop = true;
      this.videoStarted = true;
      setTimeout(() => video.play().catch(console.warn), 0);
    }
  }

  show() {
    if (this.sliceData.showOn == 'all') return true;
    if (this.sliceData.showOn == 'desktop' && this.isDesktop) return true;
    if (this.sliceData.showOn == 'mobile' && !this.isDesktop) return true;
  }
}
