import { Component, Input, OnInit, Sanitizer } from '@angular/core';
import { hideFormAnimation } from '../animations';
import { trigger, transition, style, animate } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../state.service';
import { OrderSlice } from '../order-slice';
import { ApiService } from '../api.service';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-order-slice',
  templateUrl: './order-slice.component.html',
  styleUrls: ['./order-slice.component.scss'],
  animations: [
    hideFormAnimation,
    trigger('content', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(25%)' }),
        animate(
          '250ms ease-out',
          style({ opacity: 1, transform: 'translateY(0%)' })
        )
      ])
    ])
  ]
})
export class OrderSliceComponent implements OnInit {
  @Input() sliceData: OrderSlice;

  hide = false;
  showContent = false;

  showEmailForm = true;
  showAddressForm = false;
  currentStep: number;

  constructor(
    public router: Router,
    private state: StateService,
    private route: ActivatedRoute,
    private api: ApiService,
    private lang: LanguageService
  ) {
    this.state.showRouter.subscribe(() =>
      setTimeout(() => (this.showContent = true), 500)
    );
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let step = parseInt(params['step']);
      if (!step || isNaN(step)) step = 1;
      this.handleStep(step);
    });
  }

  handleStep(step: number) {
    if (step == this.currentStep) return;
    if (step === 1) {
      this.showEmailForm = true;
      this.showAddressForm = false;
    } else if (step === 2) {
      if (!this.api.token) this.goToStep(1);
      this.showEmailForm = false;
      this.showAddressForm = true;
    }
    this.currentStep = step;
  }

  goToStep(step: number) {
    if (step == this.currentStep) return;
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { step },
      queryParamsHandling: 'merge'
    });
  }

  // public onFormUpdate(val: string) {
  //   if (val === 'started') this.hide = true;
  //   else this.hide = false;
  // }

  goToEmailStep() {
    this.showEmailForm = true;
    this.showAddressForm = false;
    this.currentStep = 1;
  }

  onEmailSubmit(val: 'started' | 'success' | 'error') {
    if (val === 'started') this.hide = true;
    else if (val == 'success') {
      this.hide = false;
      this.goToStep(2);
    } else {
      this.hide = false;
    }
  }

  onAddressSubmit(val: 'started' | 'success' | 'error') {
    if (val === 'started') {
      this.hide = true;
    } else if (val === 'success') {
      const pathPieces = this.sliceData.successPageUrl.split('/');
      const docPath = pathPieces.pop();
      this.router.navigate([this.lang.current, 'pre-order', docPath]);
    } else {
      this.hide = false;

      if (this.api.customerData.validationCode === this.api.ERR_ORDER_ALREADY_EXISTS) {
        const pathPieces = this.sliceData.failedPageUrl.split('/');
        const docPath = pathPieces.pop();
        this.router.navigate([this.lang.current, 'pre-order', docPath]);
      }
    }
  }
}
