import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Document } from 'prismic-javascript/types/documents';
import { map, tap, first } from 'rxjs/operators';
import { PrismicService } from '../prismic.service';
import { IntroSlice } from '../intro-slice';
import { PricingSlice } from '../pricing-slice';
import { BannerSlice } from '../banner-slice';
import { FeatureSlice } from '../feature-slice';
import { ImageWithCtaSlice } from '../image-with-cta-slice';
import { OrderSlice } from '../order-slice';
import { HeroSlice } from '../hero-slice';
import { CustomContentSlice } from '../custom-content-slice';
import { VideoSlice } from '../video-slice';
import { AnchorScrollService } from '../anchor-scroll.service';
import { ShowUrlParameterSlice } from '../show-url-parameter-slice';
import { OrderConfirmationSlice } from '../order-confirmation-slice';
import { LanguageService } from "../language.service";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  slices: any[];
  RichText: any;

  showCallUs = false;
  hotlineColor: 'default' | 'white' | 'black' = 'default';

  isVariant: boolean;

  constructor(
    private route: ActivatedRoute,
    private prismic: PrismicService,
    private title: Title,
    private anchorScrollService: AnchorScrollService,
    private sanitizer: DomSanitizer,
    private lang: LanguageService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data: { prismicContent: Document }) => {
      this.isVariant = window.abTest?.[data.prismicContent.uid];

      this.convertPrismicContent(data.prismicContent.data);
    });

    this.anchorScrollService.listen();
  }

  convertPrismicContent(content) {
    this.showCallUs = !!content.show_hotline_in_header;
    this.hotlineColor = content.hotline_color;
    if (content.window___tab_title) {
      this.title.setTitle(content.window___tab_title);
    } else {
      this.title.setTitle('Swype');
    }

    let promises = content.body
      .filter(
        slice =>
          !slice.slice_label ||
          (this.isVariant
            ? slice.slice_label == 'variant'
            : slice.slice_label == 'original')
      )
      .map(slice => {
        if (slice.slice_type == 'intro') {
          return this.convertToIntroSlice(slice);
        }
        if (slice.slice_type == 'pricing') {
          return this.convertToPricingSlice(slice);
        }
        if (slice.slice_type == 'banner') {
          return this.convertToBannerSlice(slice);
        }
        if (slice.slice_type == 'features') {
          return this.convertToFeatureSlice(slice);
        }
        if (slice.slice_type == 'image_with_cta') {
          return this.convertToImageWithCtaSlice(slice);
        }
        if (slice.slice_type == 'order_form') {
          return this.convertToOrderSlice(slice);
        }
        if (slice.slice_type == 'hero') {
          return this.convertToHeroSlice(slice);
        }
        if (slice.slice_type == 'custom_content') {
          return this.convertToCustomContentSlice(slice);
        }
        if (slice.slice_type == 'video') {
          return this.convertToVideoSlice(slice);
        }
        if (slice.slice_type == 'show_url_parameter') {
          return this.convertToShowUrlParamterSlice(slice);
        }
        if (slice.slice_type == 'order_confirmation') {
          return this.convertToOrderConfirmationSlice(slice);
        }
      });
    Promise.all(promises).then(res => {
      this.slices = res.filter(s => s);
    });
  }

  convertToOrderConfirmationSlice(slice): OrderConfirmationSlice {
    return {
      type: 'order_confirmation',
      id: slice.primary.id,
      title: slice.primary.title,
      showAppDownloadButtons: slice.primary.show_app_download,
      text: this.prismic.asHtml(slice.primary.text),
      image: slice.primary.image?.url,
      appDownloadText: this.sanitizer.bypassSecurityTrustHtml(
        this.prismic.asHtml(slice.primary.app_download_text)
      )
    };
  }

  convertToShowUrlParamterSlice(slice): ShowUrlParameterSlice {
    return {
      type: 'show-url-parameter',
      id: slice.primary.id,
      parameterName: 'mgm-code',
      text: this.prismic.asHtml(slice.primary.text)
    };
  }

  convertToVideoSlice(slice): VideoSlice {
    return {
      type: 'video',
      video: slice.primary.video,
      width: slice.primary.width,
      height: slice.primary.height,
      showControls: slice.primary.show_controls,
      showOn: slice.primary.show_on
    };
  }

  convertToCustomContentSlice(slice): Promise<CustomContentSlice> {
    return this.route.queryParamMap
      .pipe(
        map(
          (params): CustomContentSlice => {
            let content = this.prismic.asHtml(slice.primary.content);
            slice.items.forEach(replaceData => {
              if (
                replaceData.replacement_value_source == 'url-parameter' &&
                replaceData.replace_with
              ) {
                let match = /{{(.+)}}/.exec(replaceData.replace_with);
                if (match?.length >= 2) {
                  let [placeHolder, paramName] = match;
                  let paramValue = params.get(paramName);

                  let value = '';
                  if (placeHolder && paramValue) {
                    value = replaceData.replace_with.replace(
                      placeHolder,
                      paramValue
                    );
                  }
                  content = content.replace(
                    replaceData.replace_variable,
                    value
                  );
                }
              }
            });
            return {
              type: 'custom_content',
              content: this.sanitizer.bypassSecurityTrustHtml(content),
              id: slice.primary.id,
              spacing: slice.primary.spacing || 'default',
              backgroundColor: slice.primary.background_color
            };
          }
        ),
        first()
      )
      .toPromise<CustomContentSlice>();
  }

  convertToHeroSlice(slice): HeroSlice {
    return {
      type: 'hero',
      title: this.prismic.asText(slice.primary.title),
      img: slice.primary.background_image?.url,
      id: slice.primary.id
    };
  }

  convertToOrderSlice(slice): OrderSlice {
    const currentLanguage = this.lang.current;
    return {
      type: 'order_form',
      title: this.prismic.asText(slice.primary.title),
      id: slice.primary.id,
      image: slice.primary.image?.url,
      emailCTA: this.prismic.asText(slice.primary.email_cta),
      addressCTA: this.prismic.asText(slice.primary.cta),
      successPageUrl: this.prismic.asUrl(slice.primary.success_page),
      failedPageUrl: `${currentLanguage}/failed`, // TODO: remove the hardcode likw with success page
      secondaryCtaUrl: this.prismic.asUrl(
        slice.primary.secondary_link_below_email_form
      ),
      secondaryCta: this.prismic.asText(slice.primary.secondary_cta),
      cardContent: this.sanitizer.bypassSecurityTrustHtml(
        this.prismic.asHtml(slice.primary.card_content)
      )
    };
  }

  convertToImageWithCtaSlice(slice): ImageWithCtaSlice {
    return {
      type: 'image_with_cta',
      title: this.sanitizer.bypassSecurityTrustHtml(
        this.prismic.asHtml(slice.primary.title)
      ),
      id: slice.primary.id,
      claim: this.prismic.asHtml(slice.primary.claim),
      mobileCta: this.prismic.asText(slice.primary.mobile_cta),
      desktopCta: this.prismic.asText(slice.primary.desktop_cta),
      link: this.prismic.asUrl(slice.primary.cta_link),
      img: slice.primary.image?.url,
      animateImage: slice.primary.animate_image,
      showAppDownloadBtn: slice.primary.show_app_download_button,
      spacing: slice.primary.spacing || 'default',
      backgroundColor: slice.primary.background_color
    };
  }

  convertToFeatureSlice(slice): FeatureSlice {
    return {
      type: 'features',
      id: slice.primary.id,
      spacing: slice.primary.spacing || 'default',
      backgroundColor: slice.primary.background_color,
      items: slice.items.map(item => {
        return {
          title: this.prismic.asText(item.title),
          text: this.prismic.asHtml(item.text),
          img: item.image2?.url
        };
      })
    };
  }

  convertToBannerSlice(slice): BannerSlice {
    return {
      type: 'banner',
      id: slice.primary.id,
      text: this.sanitizer.bypassSecurityTrustHtml(
        this.prismic.asHtml(slice.primary.text)
      ),
      cta: this.prismic.asText(slice.primary.cta),
      ctaLink: this.prismic.asUrl(slice.primary.cta_link),
      icon: slice.primary.icon?.url,
      backgroundColor: slice.primary.background_color || 'white'
    };
  }

  convertToPricingSlice(slice): PricingSlice {
    return {
      type: 'pricing',
      id: slice.primary.id,
      title: this.prismic.asText(slice.primary.title),
      dailyPrice: this.prismic.asText(slice.primary.daily_price),
      dailyPriceText: this.prismic.asHtml(slice.primary.daily_price_text),
      dataPrice: this.prismic.asText(slice.primary.data_price),
      dataPriceText: this.prismic.asHtml(slice.primary.data_price_text),
      monthlyPrice: this.prismic.asText(slice.primary.monthly_price),
      monthlyPriceText: this.prismic.asHtml(slice.primary.monthly_price_text),
      internationalHeader: this.prismic.asText(
        slice.primary.international_header
      ),
      internationalText: this.prismic.asHtml(slice.primary.international_text),
      tooltipText: this.prismic.asHtml(slice.primary.tooltip_text)
    };
  }
  convertToIntroSlice(slice): IntroSlice {
    return {
      type: 'intro',
      id: slice.primary.id,
      title: this.prismic.asText(slice.primary.title),
      showCountdown: slice.primary.show_countdown,
      featureText: this.prismic.asText(slice.primary.feature_text),
      ctaLink: this.prismic.asText(slice.primary.cta_link),
      ctaText: this.prismic.asText(slice.primary.cta_text),
      scrollTarget: slice.primary.scroll_target,
      countdownTo: slice.items
        .filter(i => i.countdown_to)
        .map(i => new Date(i.countdown_to)),
      showAppDownloadButtonOnMobile:
        slice.primary.show_app_download_button_on_mobile,
      appDownloadButtonMobileText: this.prismic.asText(
        slice.primary.app_download_button_mobile_text
      )
    };
  }
}
