import { Injectable } from '@angular/core';
import { utcToZonedTime } from 'date-fns-tz';

enum DayOfWeek {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

@Injectable({
  providedIn: 'root',
})
export class OpeningHoursService {
  openingHours = {
    MONDAY: { from: 8, to: 21 },
    TUESDAY: { from: 8, to: 21 },
    WEDNESDAY: { from: 8, to: 21 },
    THURSDAY: { from: 8, to: 21 },
    FRIDAY: { from: 8, to: 21 },
    SATURDAY: { from: 10, to: 14 },
  };

  constructor() {}

  isHotlineOpen = (date: Date) => {
    let zoned;
    try {
     zoned = utcToZonedTime(date, 'Europe/Berlin');
    } catch {
      //if named TZ are not supported (mainly IE)
      zoned = date;
    }
    let hrs = this.openingHours[DayOfWeek[zoned.getDay()]];
    if (!hrs) return false;
    return zoned.getHours() >= hrs.from && zoned.getHours() < hrs.to;
  };
}
