import { Component, Output, EventEmitter, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { Delay } from '../libs/delay';
import { ToastsService } from '../toasts.service';
import { TrackingService } from '../tracking.service';

@Component({
  selector: 'app-address-email-form',
  templateUrl: './address-email-form.component.html',
  styleUrls: ['./address-email-form.component.scss']
})
export class AddressEmailFormComponent {
  email: string = '';
  phone: string = '';

  @Input() btnText: string;
  @Input() light: boolean;

  @Output() onFormUpdate = new EventEmitter(true);

  constructor(
    private api: ApiService,
    private toasts: ToastsService,
    public tracking: TrackingService
  ) {
    let search = window.location.search || '';
    let token = search.split('token=')[1] || '';
    token = token.split('&')[0].split('#')[0];
    if (token) this.api.token = token;
  }

  onSubmit(form: NgForm) {
    if (!form.valid){
      return this.tracking.submitForm('submit email', form);
    }
    this.onFormUpdate.next('started');

    const promises: Promise<boolean | void | boolean[]>[] = [
      this.api.submitEmail({ email: this.email }),
      Delay(500)
    ];
    if (this.phone) {
      const cleanMsisdn = this.cleanUpMsisdn(this.phone);
      promises.push(this.api.sendDownloadLink(cleanMsisdn));
    }

    Promise.all(promises)
      .then(results => {
        const resp = results[0];
        if (!resp) {
          throw 'server error';
        }
        this.onFormUpdate.next('success');
        this.tracking.submitForm('submit email', form);
      })
      .catch(err => {
        this.onFormUpdate.next('error');
        this.toasts.add('ADDRESS_SIGNUP_ERROR', false);
        this.tracking.submitForm('submit email', form, err);
        console.error(err);
      });
  }

  /**
   * Removes all whitespaces, and replaces
   * +41 / 0041 with just a zero, as the backend
   * service expects a Swiss mobile phone number.
   */
  private cleanUpMsisdn(phone: string) {
    let msisdn = phone.replace(/\s/g, '');
    msisdn = msisdn.replace(/^\+41/, '0');
    msisdn = msisdn.replace(/^0041/, '0');
    return msisdn.replace(/^00/, '0');
  }

  blurField(form: any, fieldName: string) {
    if (!form.form.controls[fieldName].valid) {
      return;
    }
    this.tracking.formFieldBlur('submit email', fieldName);
  }
}
