<div class="slice full-width" [attr.id]="sliceData.id">
  <div>
    <div *ngIf="showContent" [@content] class="background-container">
      <div>
        <h1>{{sliceData.title}}</h1>
      </div>
      <div class="bg-split">
        <div class="card ">
          <div class="flex col img-container" *ngIf="sliceData.image">
            <img [src]="sliceData.image" />
          </div>
          <div class="flex col content" [innerHtml]="sliceData.cardContent">
          </div>
        </div>
      </div>
      <div class="bg-light">
        <div class="steps">
          <span [ngClass]="{active: currentStep == 1}" (click)="goToEmailStep()" style="cursor: pointer">1</span> <span> - </span><span
            [ngClass]=" {active: currentStep == 2}">2</span><span> - </span><span>3</span>
        </div>
        <div [@pageContent]="hide ? 'hidden' : 'shown'" class="order-form">
          <!-- <app-address-form (onFormUpdate)="onFormUpdate($event)" *ngIf="!showAddressForm && !showEmailForm">
          </app-address-form> -->
          <app-address-email-form [hidden]="!showEmailForm" (onFormUpdate)="onEmailSubmit($event)"
            [btnText]="sliceData.emailCTA" [light]="true">
          </app-address-email-form>
          <app-address-postal-form [hidden]="!showAddressForm" (onFormUpdate)="onAddressSubmit($event)"
            [btnText]="sliceData.addressCTA" [light]="true"></app-address-postal-form>
          <a *ngIf="showEmailForm" [routerLink]="[sliceData.secondaryCtaUrl]"
            class="secondary-cta">{{sliceData.secondaryCta}}</a>
        </div>
      </div>
    </div>
  </div>
