import { Injectable } from '@angular/core';
import Prismic from 'prismic-javascript';
import { DefaultClient } from 'prismic-javascript/types/client';
import { Document } from 'prismic-javascript/types/documents';
import { Link, RichText } from 'prismic-dom';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class PrismicService {
  apiEndpoint = 'https://sunrise-swype.cdn.prismic.io/api/v2';
  languages = { de: 'de-ch', en: 'en-gb', fr: 'fr-ch', it: 'it-ch' };

  client: DefaultClient;

  homeUID = 'home';

  constructor(private lang: LanguageService) {
    this.client = Prismic.client(this.apiEndpoint);
  }

  async getByUid(uid: string): Promise<Document> {
    let local = this.languages[this.lang.current];
    let result = await this.client.getByUID(
      'landing_page',
      !uid ? this.homeUID : uid,
      {
        lang: local
      }
    );
    return result;
  }

  async getById(id: string): Promise<Document> {
    return await this.client.getByID(id, {});
  }

  async getPreviewUrl(token, documentId) {
    let result = await this.getById(documentId);
    let lang = Object.entries(this.languages).find(
      ([_, v]) => v == result.lang
    )?.[0];
    const resolver = await this.client.getPreviewResolver(token, documentId);
    return resolver.resolve(this.getLinkResolverFn(lang), '/');
  }

  public getLinkResolverFn(lang?) {
    lang = lang ?? this.lang.current;
    let homeUID = this.homeUID;
    return doc => {
      let path = doc.uid === homeUID ? '' : doc.uid;
      return `/${lang}/${path}`;
    };
  }

  asHtml(raw): string {
    if (raw) {
      return RichText.asHtml(raw, this.getLinkResolverFn())
        .replace(/&amp;/g, '&')
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<')
        .replace(/&quot;/g, '"')
        .replace(/ /g, ' ');
    } else {
      return '';
    }
  }

  asText(raw): string {
    return raw ? RichText.asText(raw) : '';
  }

  asUrl(raw) {
    return Link.url(raw, this.getLinkResolverFn());
  }
}
