<ng-template #bannerContent> <img src="{{sliceData.icon}}" class="banner-logo" alt="">
  <div class="text" [innerHTML]="sliceData.text"></div>
  <div class="cta" *ngIf="sliceData.cta">
    <span>{{sliceData.cta}}</span>
    <img *ngIf="['gray', 'red'].includes(sliceData.backgroundColor)" src="/assets/icons/half-logo.svg" class="arrow"
      alt="">
    <img *ngIf="sliceData.backgroundColor == 'white'" src="/assets/icons/half-logo-gray.svg" class="arrow" alt="">
  </div>
</ng-template>

<div class="slice full-width headless bottomless" [class]="{
  'primary': sliceData.backgroundColor == 'red', 
  'white': sliceData.backgroundColor == 'white', 
  'lighter': sliceData.backgroundColor == 'gray'
}" [attr.id]="sliceData.id">
  <div class="content">
    <a *ngIf="sliceData.ctaLink" routerLink="{{sliceData.ctaLink}}" class="banner"
      (click)="tracking.ctaClick('order now')">
      <ng-container *ngTemplateOutlet="bannerContent"></ng-container>
    </a>
    <div *ngIf="!sliceData.ctaLink" class="banner">
      <ng-container *ngTemplateOutlet="bannerContent"></ng-container>
    </div>
  </div>
</div>
