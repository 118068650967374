import { Component, OnInit, Input } from '@angular/core';
import {
  transition,
  trigger,
  state,
  style,
  animate
} from '@angular/animations';
import { hideFormAnimation } from '../animations';
import { FeatureSlice } from '../feature-slice';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss'],
  animations: [
    hideFormAnimation,
    trigger('phone', [
      state(
        'shown',
        style({
          opacity: 1,
          transform:
            window.innerWidth > 700 ? 'translateX(0%)' : 'translateY(0%)',
          left: '0%'
        })
      ),
      state(
        'full',
        style({
          opacity: 1,
          transform:
            window.innerWidth > 700 ? 'translateX(0)' : 'translateY(0%)',
          left: '27%'
        })
      ),
      state(
        'hidden',
        style({
          opacity: 0,
          transform:
            window.innerWidth > 700 ? 'translateX(25%)' : 'translateY(-10%)',
          left: '0%'
        })
      ),
      transition('hidden => shown', animate('0.4s ease-out')),
      transition('shown => full', animate('0.4s ease-out'))
    ])
  ]
})
export class FeatureComponent implements OnInit {
  @Input() sliceData: FeatureSlice;
  featureShown = {};

  constructor() {}

  ngOnInit(): void {}

  isFeatureShown(id) {
    return !!this.featureShown[id];
  }

  setFeatureShown(id, value) {
    this.featureShown[id] = value;
  }
}
