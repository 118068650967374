import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LangaugeResolve } from './language.resolve';
import { LoadingComponent } from './loading/loading.component';
import { ToastsComponent } from './toasts/toasts.component';
import { AddressFormComponent } from './address-form/address-form.component';
import { TokenCheckResolve } from './token-check.resolve';
import { SplashComponent } from './splash/splash.component';
import { SiteFooterComponent } from './site-footer/site-footer.component';
import { CookieBannerComponent } from './cookie-banner/cookie-banner.component';
import { LanguageSwitcherComponent } from './language-switcher/language-switcher.component';
import { SiteHeaderComponent } from './site-header/site-header.component';
import { PhoneComponent } from './phone/phone.component';
import { MenuComponent } from './menu/menu.component';
import { AnimatedSimComponent } from './animated-sim/animated-sim.component';
import { LottieModule } from 'ngx-lottie';
import { InViewDirective } from './in-view.directive';
import { FaqsComponent } from './faqs/faqs.component';
import { SocialIconsComponent } from './social-icons/social-icons.component';
import { ScrollIndicatorComponent } from './scroll-indicator/scroll-indicator.component';
import { ImprintPageComponent } from './imprint-page/imprint-page.component';
import { SendDownloadLinkFormComponent } from './send-download-link-form/send-download-link-form.component';
import { ImageWithCTAComponent } from './image-with-cta/image-with-cta.component';
import { LazyTranslateLoader } from './lazy-translate-loader';
import { CallUsComponent } from './call-us/call-us.component';
import { CountdownComponent } from './countdown/countdown.component';
import { ReferrerTrackingResolve } from './referrer-tracking.resolve';
import { DownloadButtonComponent } from './download-button/download-button.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { IntroComponent } from './intro/intro.component';
import { PricingComponent } from './pricing/pricing.component';
import { BannerComponent } from './banner/banner.component';
import { FeatureComponent } from './feature/feature.component';
import { OrderSliceComponent } from './order-slice/order-slice.component';
import { HeroSliceComponent } from './hero-slice/hero-slice.component';
import { CustomContentComponent } from './custom-content/custom-content.component';
import { VideoComponent } from './video/video.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { InfluencerTrackingResolve } from './influencer-tracking.resolve';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ShowUrlParameterSliceComponent } from './show-url-parameter-slice/show-url-parameter-slice.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AddressPostalFormComponent } from './address-postal-form/address-postal-form.component';
import { AddressEmailFormComponent } from './address-email-form/address-email-form.component';
import { OrderConfirmationSliceComponent } from './order-confirmation-slice/order-confirmation-slice.component';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return import('lottie-web');
}
@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    ToastsComponent,
    AddressFormComponent,
    SplashComponent,
    SiteFooterComponent,
    CookieBannerComponent,
    LanguageSwitcherComponent,
    SiteHeaderComponent,
    PhoneComponent,
    MenuComponent,
    AnimatedSimComponent,
    InViewDirective,
    FaqsComponent,
    SocialIconsComponent,
    ScrollIndicatorComponent,
    ImprintPageComponent,
    SendDownloadLinkFormComponent,
    ImageWithCTAComponent,
    CallUsComponent,
    CountdownComponent,
    DownloadButtonComponent,
    LandingPageComponent,
    IntroComponent,
    PricingComponent,
    BannerComponent,
    FeatureComponent,
    OrderSliceComponent,
    HeroSliceComponent,
    CustomContentComponent,
    VideoComponent,
    NotFoundPageComponent,
    TooltipComponent,
    ShowUrlParameterSliceComponent,
    AddressEmailFormComponent,
    AddressPostalFormComponent,
    OrderConfirmationSliceComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    LottieModule.forRoot({ player: playerFactory, useCache: true }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader
      }
    }),
    FontAwesomeModule
  ],
  providers: [
    LangaugeResolve,
    TokenCheckResolve,
    ReferrerTrackingResolve,
    InfluencerTrackingResolve
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
