import { Component, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { Delay } from '../libs/delay';
import { ToastsService } from '../toasts.service';
import { Router } from '@angular/router';
import { LanguageService } from '../language.service';
import { TrackingService } from '../tracking.service';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent {
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  city: string = '';
  zip: string = '';
  streetName: string = '';
  streetNumber: string = '';
  submitting: boolean = false;
  requireEmail: boolean;

  @Output() onFormUpdate = new EventEmitter(true);

  constructor(
    private api: ApiService,
    private toasts: ToastsService,
    private router: Router,
    private lang: LanguageService,
    public tracking: TrackingService
  ) {
    let search = window.location.search || '';
    let token = search.split('token=')[1] || '';
    token = token.split('&')[0].split('#')[0];
    if (token) this.api.token = token;
    this.requireEmail = !api.token;
  }

  onSubmit(form: NgForm) {
    if (!form.valid) return this.tracking.submitForm('submit address', form);
    this.submitting = true;
    this.onFormUpdate.next('started');
    Promise.all([
      this.api.submitEmail({
        email: this.email,
        address: {
          firstName: this.firstName,
          lastName: this.lastName,
          city: this.city,
          zip: this.zip,
          street: this.streetName,
          streetNumber: this.streetNumber
        }
      }),
      Delay(500)
    ])
      .then(results => {
        let resp = results[0];
        if (!resp) {
          throw 'server error';
        }
        this.onFormUpdate.next('success');
        this.submitting = false;
        let path = window.location.pathname.split('/').pop();
        this.router.navigate([this.lang.current, path, 'success']);
        this.tracking.submitForm('submit address', form);
      })
      .catch(err => {
        this.onFormUpdate.next('error');
        this.submitting = false;
        this.toasts.add('ADDRESS_SIGNUP_ERROR', false);
        this.tracking.submitForm('submit address', form, err);
        console.error(err);
      });
  }

  blurField(form: any, fieldName: string) {
    if (!form.form.controls[fieldName].valid) return;
    this.tracking.formFieldBlur('submit address', fieldName);
  }
}
