import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public current: string = 'de';
  public langs = ['de', 'en', 'fr', 'it'];

  constructor(public translate: TranslateService, private router: Router) {
    this.switchLang(translate.getBrowserLang(), true);

    this.router.events.subscribe((val: NavigationEnd | NavigationStart) => {
      let lang: string;

      if (val.url !== undefined) {
        lang = val.url.split('/')[1];
        lang = lang.split('#')[0];
        lang = lang.split('?')[0];
      }

      if (val instanceof NavigationStart) {
        //so resolvers / guards already have the correct new language
        if (this.langs.includes(lang)) {
          this.current = lang;
        }
      }

      if (!(val instanceof NavigationEnd)) return;
      //only update translations once navigation is done, otherwise there's a flickering between old and new
      this.translate.use(this.current);
      const langAttr = document.createAttribute('lang');
      langAttr.value = this.current;
      document.querySelector('html').attributes.setNamedItem(langAttr);
    });
  }

  switchLang(lang: string, initial: boolean = false) {
    if (!this.langs.includes(lang)) {
      lang = this.langs[0];
    }
    if (initial) return (this.current = lang);
    const queryParams = this.router.parseUrl(this.router.url).queryParams;
    const path = this.router.url.split('?')[0].split('/').slice(2);
    this.router.navigate([lang, ...path], { queryParams: queryParams });
  }
}
