<div class="page-wrapper">
  <div class="page main">
    <div class="page-content">
      <app-site-header [showCallUs]="showCallUs" [hotlineColor]="hotlineColor"></app-site-header>

      <div *ngFor="let item of slices">
        <app-intro *ngIf="item.type == 'intro'" [sliceData]="item"></app-intro>
        <app-pricing *ngIf="item.type == 'pricing'" [sliceData]="item"></app-pricing>
        <app-banner *ngIf="item.type == 'banner'" [sliceData]="item"></app-banner>
        <app-feature *ngIf="item.type == 'features'" [sliceData]="item"></app-feature>
        <app-image-with-cta *ngIf="item.type == 'image_with_cta'" [sliceData]="item"></app-image-with-cta>
        <app-order-slice *ngIf="item.type == 'order_form'" [sliceData]="item"></app-order-slice>
        <app-hero-slice *ngIf="item.type == 'hero'" [sliceData]="item"></app-hero-slice>
        <app-custom-content *ngIf="item.type == 'custom_content'" [sliceData]="item"></app-custom-content>
        <app-video *ngIf="item.type == 'video'" [sliceData]="item"></app-video>
        <app-show-url-parameter-slice *ngIf="item.type == 'show-url-parameter'" [sliceData]="item">
        </app-show-url-parameter-slice>
        <app-order-confirmation-slice *ngIf="item.type == 'order_confirmation'" [sliceData]="item">
        </app-order-confirmation-slice>
      </div>
    </div>
  </div>
  <div class="page no-gradient">
    <app-site-footer></app-site-footer>
  </div>
</div>
