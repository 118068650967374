<div class="slice" [class]="{
  'primary': sliceData.backgroundColor == 'red', 
  'white': sliceData.backgroundColor == 'white', 
  'lighter': sliceData.backgroundColor == 'gray',
  'big-spacing': sliceData.spacing == 'default',
  'headless': sliceData.spacing == 'headless',
  'bottomless': sliceData.spacing == 'bottomless'
}" [attr.id]="sliceData.id">

  <div class="content content-splitter" id="early-access">
    <div class="split-content center-content-vertically-on-desktop">
      <app-animated-sim *ngIf="sliceData.animateImage && sliceData.img" [imgSrc]="sliceData.img" class="sim-card">
      </app-animated-sim>
      <img *ngIf="!sliceData.animateImage && sliceData.img" [src]="sliceData.img" />
    </div>
    <div class="split-content center-content-vertically-on-desktop space-above-on-mobile">
      <div class="page-intro center-text-on-mobile">
        <span class="page-sub-title" [innerHtml]="sliceData.title"></span>
        <p class="page-claim">
          <span [innerHTML]="sliceData.claim"></span>
        </p>
        <app-download-button *ngIf="sliceData.showAppDownloadBtn" [mobileCtaText]="sliceData.mobileCta"
          [desktopCtaText]="sliceData.desktopCta"
          [buttonExtraClass]="'big full-width bottomless' + (sliceData.backgroundColor == 'red' ? ' gray' : '')">
        </app-download-button>
        <a *ngIf="!sliceData.showAppDownloadBtn && sliceData.link && sliceData.desktopCta" [href]="sliceData.link"
          target="_blank" class="button inline big" [class]="{
            'gray': sliceData.backgroundColor == 'red'}">{{sliceData.desktopCta}}</a>
      </div>
    </div>
  </div>
</div>
