import { Component, Input } from '@angular/core';
import { LanguageService } from '../language.service';
import {
  trigger,
  state,
  transition,
  style,
  animate
} from '@angular/animations';
import { StateService } from '../state.service';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
  animations: [
    trigger('menu', [
      transition(':enter', [
        style({ transform: 'translateX(100vw)' }),
        animate('250ms ease-in', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0vw)' }),
        animate('250ms ease-in', style({ transform: 'translateX(100vw)' }))
      ])
    ]),
    trigger('logo', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('* => *', animate('0.5s ease'))
    ]),
    trigger('hotline', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('hidden => shown', animate('0.5s ease'))
    ])
  ]
})
export class SiteHeaderComponent {
  @Input() showCallUs: boolean;
  @Input() hotlineColor: 'default' | 'white' | 'black';

  constructor(public lang: LanguageService, public state: StateService) {}
  toggleMenu() {
    this.state.menuActive = !this.state.menuActive;
  }
}
