<div class="site-header">
  <h1 class="logo-container" [@logo]="state.menuActive ? 'hidden': 'shown'">
    <a routerLink="/{{lang.current}}">

      <img src="/assets/swype_logo_white.png" class="logo" alt="swype">
    </a>
  </h1>
  <app-call-us *ngIf="showCallUs" [@hotline]="state.menuActive ? 'hidden': 'shown'" [class]="hotlineColor">
  </app-call-us>
  <div class="menu-toggle-container" (click)="toggleMenu()" [ngClass]="{fix: state.menuActive}">
    <div class="menu-toggle" [ngClass]="{open: state.menuActive}">
      <span></span>
      <span></span>
    </div>
  </div>
</div>

<app-menu *ngIf="state.menuActive" [@menu]></app-menu>
