import { Component, OnInit } from '@angular/core';
import { ToastsService } from '../toasts.service';
import { Toast } from '../toast.class';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
  animations: [
    trigger('toast', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-100%) scale(0.75)', position: 'relative' }),
        animate('250ms', style({ transform: 'translateY(0) scale(1)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)', position: 'relative'}),
        animate('250ms', style({ transform: 'translateY(-100%) scale(0)', opacity: 0 }))
      ])
    ])
  ]
})
export class ToastsComponent implements OnInit {
  toasts: Toast[] = []
  
  constructor(
    private toastsService: ToastsService
  ) { }

  ngOnInit() {
    this.toastsService.toasts.subscribe((resp: Toast) => {
      this.toasts.push(resp)
      setTimeout(() => {
        this.toasts.splice(this.toasts.indexOf(resp), 1)
      }, resp.duration)
    })
  }

}
