import { Component } from '@angular/core';
import { LanguageService } from '../language.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
  animations: [
    trigger('cookieBanner', [
      transition(':leave', [
        style({ transform: 'translateY(0)', height: '44px' }),
        animate('250ms', style({ transform: 'translateY(-100%)', height: '0' }))
      ])
    ])
  ]
})
export class CookieBannerComponent {
  showCookieBanner = false
  showCookieBannerContainer = false

  constructor(public lang: LanguageService) {
    setTimeout(() => {
      this.showCookieBanner = !localStorage.getItem('hideCookieBanner');
      this.showCookieBannerContainer = this.showCookieBanner
    }, 2000)
  }

  hideCookieBanner() {
    this.showCookieBanner = false
    localStorage.setItem('hideCookieBanner', 'true')
    setTimeout(() => this.showCookieBannerContainer = false, 300)
  }
}
