import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent {
  faqs: {key: string, isOpen: boolean}[] = []

  constructor(private elRef: ElementRef) {
    for (let i = 0; i < 6; i++) {
      this.faqs.push({ key: (i + 1).toString(), isOpen: false})
    }
  }

  getFaqHeight(faq: { key: string, isOpen: boolean }) {
    return this.elRef.nativeElement.querySelectorAll('.faq .faq-body p')[parseInt(faq.key) - 1].scrollHeight + 16;
  }
}
