import { Component, NgZone } from '@angular/core';
import { LanguageService } from '../language.service';
import { Router } from '@angular/router';
import { StateService } from '../state.service';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { TrackingService } from '../tracking.service';
import { ResizeService } from '../resize.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  public lottieConfig: AnimationOptions;
  private anim: AnimationItem;
  showLogo = window.innerWidth >= 350;
  public logoSize = window.innerWidth < 700 ? window.innerWidth : 600;
  isDesktop: boolean;
  styles: Partial<CSSStyleDeclaration> = {
    margin: '0 auto'
  };

  constructor(
    public lang: LanguageService,
    public router: Router,
    public state: StateService,
    private ngZone: NgZone,
    public tracking: TrackingService,
    private resizeService: ResizeService
  ) {
    this.lottieConfig = {
      path: 'assets/lottie/Logo-Short.json',
      renderer: 'canvas',
      loop: false,
      autoplay: false
    };
    this.resizeService.data.subscribe(data => {
      this.isDesktop = data.isDesktop;
    });
  }

  handleAnimation(anim: AnimationItem) {
    this.anim = anim;
    setTimeout(
      () => this.ngZone.runOutsideAngular(() => this.anim.play()),
      300
    );
    setTimeout(
      () => this.ngZone.runOutsideAngular(() => this.anim.pause()),
      1300
    );
  }

  openAppStore() {
    this.tracking.ctaClick('download link menu');
    setTimeout(() => {
      window.location.href = this.tracking.getDownloadLink();
    }, 250);
  }
}
