import { Component, OnInit, Input } from '@angular/core';
import { hideFormAnimation } from '../animations';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { StateService } from '../state.service';
import { TrackingService } from '../tracking.service';
import { LanguageService } from '../language.service';
import { IntroSlice } from '../intro-slice';
import { ResizeService } from '../resize.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
  animations: [
    hideFormAnimation,
    trigger('content', [
      state('shown', style({ opacity: 1, transform: 'translateY(0%)' })),
      state('hidden', style({ opacity: 0, transform: 'translateY(25%)' })),
      transition('hidden => shown', animate('0.25s ease-out')),
      transition('shown => hidden', animate('0.4s ease-out'))
    ]),
    trigger('form', [
      state('shown', style({ opacity: 1, transform: 'translateY(0%)' })),
      state('hidden', style({ opacity: 0, transform: 'translateY(25%)' })),
      transition('hidden => shown', animate('0.25s ease-out'))
    ]),
    trigger('phone', [
      state(
        'shown',
        style({
          opacity: 1,
          transform:
            window.innerWidth > 700 ? 'translateX(0%)' : 'translateY(0%)',
          left: '0%'
        })
      ),
      state(
        'full',
        style({
          opacity: 1,
          transform:
            window.innerWidth > 700 ? 'translateX(0)' : 'translateY(0%)',
          left: '27%'
        })
      ),
      state(
        'hidden',
        style({
          opacity: 0,
          transform:
            window.innerWidth > 700 ? 'translateX(25%)' : 'translateY(-10%)',
          left: '0%'
        })
      ),
      transition('hidden => shown', animate('0.4s ease-out')),
      transition('shown => full', animate('0.4s ease-out'))
    ])
  ]
})
export class IntroComponent implements OnInit {
  @Input() sliceData: IntroSlice;
  // @Input() signupPageClaim;

  hide = false;
  showContent = false;
  showForm = false;
  showPhone = false;
  alreadyInited = false;
  scrollIndicator = false;
  showScrollIndicator = window.innerWidth >= 700;

  isDesktop;

  constructor(
    private state: StateService,
    public tracking: TrackingService,
    public lang: LanguageService,
    private resizeService: ResizeService
  ) {}

  ngOnInit(): void {
    this.state.showRouter.subscribe(() => {
      if (this.alreadyInited) return;
      this.alreadyInited = true;
      setTimeout(() => (this.showPhone = true), 150);
      setTimeout(() => (this.showContent = true), 350);
      setTimeout(() => (this.showForm = true), 550);
    });
    this.resizeService.data.subscribe(data => {
      this.isDesktop = data.isDesktop;
    });
  }

  onFormUpdate(val: string) {
    if (val === 'started') this.hide = true;
    if (val === 'error') this.hide = false;
  }

  scrollDown() {
    document.getElementById(this.sliceData.scrollTarget).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }
}
