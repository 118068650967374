import { Component, Output, EventEmitter, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { Delay } from '../libs/delay';
import { ToastsService } from '../toasts.service';
import { TrackingService } from '../tracking.service';

@Component({
  selector: 'app-address-postal-form',
  templateUrl: './address-postal-form.component.html',
  styleUrls: ['./address-postal-form.component.scss']
})
export class AddressPostalFormComponent {
  firstName: string = '';
  lastName: string = '';
  city: string = '';
  zip: string = '';
  streetName: string = '';
  streetNumber: string = '';
  submitting: boolean = false;

  @Input() btnText: string;
  @Input() light: boolean;

  @Output() onFormUpdate = new EventEmitter(true);

  constructor(
    private api: ApiService,
    private toasts: ToastsService,
    public tracking: TrackingService
  ) {
    let search = window.location.search || '';
    let token = search.split('token=')[1] || '';
    token = token.split('&')[0].split('#')[0];
    if (token) this.api.token = token;
  }

  onSubmit(form: NgForm) {
    if (!form.valid) return this.tracking.submitForm('submit address', form);
    this.submitting = true;
    this.onFormUpdate.next('started');
    Promise.all([
      this.api.submitAddress({
        firstName: this.firstName,
        lastName: this.lastName,
        city: this.city,
        zip: this.zip,
        street: this.streetName,
        streetNumber: this.streetNumber
      }),
      Delay(500)
    ])
      .then(results => {
        let resp = results[0];
        if (!resp) {
          throw 'server error';
        }
        this.submitting = false;

        if (this.api.customerData.validationCode === this.api.ERR_ORDER_ALREADY_EXISTS) {
          this.onFormUpdate.next('error');
          this.tracking.submitForm('submit address', form, this.api.ERR_ORDER_ALREADY_EXISTS);
        } else {
          this.onFormUpdate.next('success');
          this.tracking.submitForm('submit address', form);
        }
      })
      .catch(err => {
        this.onFormUpdate.next('error');
        this.submitting = false;
        this.toasts.add('ADDRESS_SIGNUP_ERROR', false);
        this.tracking.submitForm('submit address', form, err);
        console.error(err);
      });
  }

  blurField(form: any, fieldName: string) {
    if (!form.form.controls[fieldName].valid) return;
    this.tracking.formFieldBlur('submit address', fieldName);
  }
}
