<div class="menu">
  <div class="menu-items">
    <div [style]="{width: logoSize * 2+'px'}" *ngIf="showLogo">
      <ng-lottie [options]="lottieConfig" [height]="logoSize + 'px'" (animationCreated)="handleAnimation($event)"
        [styles]="styles">
      </ng-lottie>
    </div>
    <a routerLink="/{{lang.current}}" fragment="top" (click)="state.menuActive = false">
      {{'MENUITEMHOME' | translate}}
    </a>
    <a routerLink="/{{lang.current}}" fragment="features"
      (click)="state.menuActive = false">{{'MENUMAINFEATURES' | translate}}</a>
    <a routerLink="/{{lang.current}}" fragment="pricing"
      (click)="state.menuActive = false">{{'MENUPRICING' | translate}}</a>
    <a
      href="https://support.yalloswype.ch/hc/{{lang.current == 'en' ? 'en-gb' : lang.current}}">{{'MENUITEMFAQS' | translate}}</a>
    <div class="btn-container">
      <a routerLink="/{{lang.current}}/pre-order" class="btn pre-order-link"
        (click)="tracking.ctaClick('order now'); state.menuActive = false;">{{'MENUITEMGETFREESIM' | translate}}</a>
      <button *ngIf="!isDesktop" class="button btn app-download-link"
        (click)="openAppStore()">{{'MENUITEMAPP' | translate}}</button>
      <app-send-download-link-form *ngIf="isDesktop" [ctaText]="'MENUITEMAPP' | translate" [buttonExtraClass]="'gray'">
      </app-send-download-link-form>
    </div>
  </div>
  <div class="menu-footer">
    <app-social-icons [iconType]="'_white'"></app-social-icons>
    <app-language-switcher></app-language-switcher>
    <app-call-us [light]="true"></app-call-us>
  </div>
</div>
