import { Component, OnInit, Input } from '@angular/core';
import { TrackingService } from '../tracking.service';
import { BannerSlice } from '../banner-slice';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() sliceData: BannerSlice;

  constructor(public tracking: TrackingService, public lang: LanguageService) {}

  ngOnInit(): void {}
}
