import { Component } from '@angular/core';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent {
  constructor(public lang: LanguageService) { }
}
