import { Component, AfterViewInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './language.service';
import { RouterOutlet, Router, NavigationEnd, Scroll } from '@angular/router';
import { routeAnimations } from './animations';
import { StateService } from './state.service';
import { ApiService } from './api.service';
import { debounce } from './libs/debounce';
import { ResizeService } from './resize.service';
import { TrackingService } from './tracking.service';

declare global {
  interface Window {
    dataLayer: any[];
    abTest: any[];
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements AfterViewInit {
  showSplash = false;
  hideSplash = false;
  showFooter = false;
  hideRouter = true;
  url: string;

  constructor(
    public translate: TranslateService,
    private lang: LanguageService,
    public router: Router,
    public state: StateService,
    private api: ApiService,
    private resizeService: ResizeService,
    private trackingService: TrackingService
  ) {
    let scrollTimeout: any;

    this.router.events.subscribe(event => {
      if (event instanceof Scroll) {
        if (event.anchor) {
          if (scrollTimeout) {
            clearTimeout(scrollTimeout);
          }
          this.scrollTo(event.anchor);
        }
        this.url = event.routerEvent.url;
      }

      if (event instanceof NavigationEnd) {
        let source = event.urlAfterRedirects.split('utm_source=')[1];
        if (source) {
          source = source.split('&')[0];
          this.api.type = source;
        }

        this.state.menuActive = false;
        scrollTimeout = setTimeout(
          () => (document.scrollingElement.scrollTop = 0),
          500
        );

        // if test is on a specific URL, the new URL is only ready at NavigationEnd
        // for tests with wider scope, it could be activated earlier, but in case of redirect tests
        // that leads to ugly cancelled network requests
        this.trackingService.triggerOptimizeActivateEvent();
        // delay reporting the virtual page view so if there's an Optimize redirect experiment,
        // only the variant is recorded as page view
        setTimeout(() => this.trackingService.triggerVirtualPageView(event), 500);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => (this.showSplash = true));
    setTimeout(() => (this.hideRouter = false), 1000);
    setTimeout(() => this.state.doShowRouter(), 2000);
    this.onResize(window);
  }

  @HostListener('window:resize', ['$event.target'])
  @debounce(300)
  onResize(event: Window) {
    this.resizeService.onResize(event.innerWidth, event.innerHeight);
  }

  switchLang(lang: string) {
    this.lang.switchLang(lang);
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  scrollTo(anchor: string) {
    let el = document.getElementById(anchor);
    let oldUrl = this.url || '';
    let duration =
      oldUrl.split('#')[0] === this.router.url.split('#')[0] ? 0 : 750;
    if (el)
      setTimeout(
        () =>
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          }),
        duration
      );
  }
}
