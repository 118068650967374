import { Component, OnInit, NgZone } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {
  public lottieConfig: AnimationOptions;
  private anim: AnimationItem;
  public logoSize = window.innerWidth < 700 ? window.innerWidth : 600;
  styles: Partial<CSSStyleDeclaration> = {
    margin: '0 auto'
  };
  constructor(private ngZone: NgZone) {
    this.lottieConfig = {
      path: 'assets/lottie/Logo-Short.json',
      loop: false,
      autoplay: false
    };
  }

  ngOnInit(): void {}

  handleAnimation(anim: AnimationItem) {
    this.anim = anim;
    setTimeout(
      () => this.ngZone.runOutsideAngular(() => this.anim.play()),
      300
    );
    setTimeout(
      () => this.ngZone.runOutsideAngular(() => this.anim.pause()),
      1500
    );
  }

  animateLogo() {
    this.lottieConfig = {
      ...this.lottieConfig,
      loop: true
    };
    setTimeout(() => {
      this.ngZone.runOutsideAngular(() => this.anim.play());
    }, 50);
  }
}
