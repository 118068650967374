import { Component, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { LanguageService } from '../language.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements AfterViewInit, OnDestroy {
  @ViewChild('video_en') video_en: ElementRef
  @ViewChild('video_de') video_de: ElementRef
  @ViewChild('video_fr') video_fr: ElementRef
  @ViewChild('video_it') video_it: ElementRef
  sub: Subscription

  constructor(public lang: LanguageService, private translate: TranslateService) { }
  
  ngAfterViewInit() {
    this.playVideo(this['video_' + this.lang.current])
    this.sub = this.translate.onLangChange.subscribe(() => this.playVideo(this['video_' + this.lang.current]))
  }

  playVideo(ref: ElementRef) {
    let video = ref.nativeElement
    video.muted = true
    video.playsinline = true
    video.loop = true
    setTimeout(() => video.play().catch(console.warn), 0)
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe()
  }
}
