<div class="form-and-loader-container">
  <div class="form-container">
    <form #addressForm="ngForm" (ngSubmit)="onSubmit(addressForm)"
      [ngClass]="{submitted: addressForm.submitted, 'dark-form': !light}" class=" small-form">
      <div class="form-row">
        <div class="form-group">
          <input type="email" email id="email" [(ngModel)]="email" [ngClass]="{'not-empty': email.length}" name="email"
            (blur)="blurField(addressForm, 'email')" required>
          <label for="email">{{'EMAILADDRESS' | translate}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <input type="tel" id="phone" [(ngModel)]="phone" [ngClass]="{'not-empty': phone.length}" name="phone"
            (blur)="blurField(addressForm, 'phone')" pattern="[0-9 +]*">
          <label for="phone">{{'MSISDN_WITH_FORMAT' | translate}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <!--
            On the website we assume that a new account is always registered. Otherwise they would have ordered the sim card on the app.
           -->
          <button type="submit" (click)="tracking.ctaClick('account register')" class="button big bottomless">{{btnText}}</button>
        </div>
      </div>
    </form>
  </div>
</div>
