<div class="phone-container">
  <div class="phone">
    <video width="480" height="960" #video_en loop muted playsinline *ngIf="lang.current === 'en'">
      <source src="https://player.vimeo.com/external/373140567.hd.mp4?s=26c3d9a754e349d6d52be888f974c34850366535&profile_id=174" type="video/mp4">
    </video>
    <video width="480" height="960" #video_de loop muted playsinline *ngIf="lang.current === 'de'">
      <source src="https://player.vimeo.com/external/373140542.hd.mp4?s=6f4d79b8e579e3e75f6118cf2c25fb583c259bd1&profile_id=174" type="video/mp4">
    </video>
    <video width="480" height="960" #video_fr loop muted playsinline *ngIf="lang.current === 'fr'">
      <source src="https://player.vimeo.com/external/373140584.hd.mp4?s=1198cc6b2a016edb2dc1c98cf7e418cb775a77fd&profile_id=174" type="video/mp4">
    </video>
    <video width="480" height="960" #video_it loop muted playsinline *ngIf="lang.current === 'it'">
      <source src="https://player.vimeo.com/external/373140604.hd.mp4?s=4ccff4eff1081492b401cac6ef37eec88a13ede3&profile_id=174" type="video/mp4">
    </video>
  </div>
</div>