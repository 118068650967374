<div class="slice" [class]="{
  'primary': sliceData.backgroundColor == 'red', 
  'white': sliceData.backgroundColor == 'white', 
  'lighter': sliceData.backgroundColor == 'gray',
  'big-spacing': sliceData.spacing == 'default',
  'headless': sliceData.spacing == 'headless',
  'bottomless': sliceData.spacing == 'bottomless'
}" [attr.id]="sliceData.id">
  <div class="content">
    <ng-template ngFor let-item [ngForOf]="sliceData.items" let-i="index" let-even="even">
      <div *ngIf="i != 0" class="video-spacer"></div>

      <div class="content-splitter with-spacing split-60-40" [ngClass]="{'reversed': even}">
        <div class="split-content big-feature-video" [@phone]="isFeatureShown(i) ? 'shown' : 'hidden'">
          <div class="" appInView (enterView)="setFeatureShown(i, true)">
            <img [src]="item.img" width="400" height="260" alt="" class="full-width">
          </div>
        </div>
        <div class="split-content center-content-vertically" [@pageContent]="isFeatureShown(i) ? 'shown' : 'hidden'">
          <div class="page-intro center-text-on-mobile">
            <h1 class="page-title">{{item.title}}</h1>
            <p class="page-text" [innerHTML]="item.text"></p>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
