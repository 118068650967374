<div class="form-and-loader-container">
  <div class="form-container">
    <form #addressForm="ngForm" (ngSubmit)="onSubmit(addressForm)" [ngClass]="{submitted: addressForm.submitted}" class="dark-form small-form">
      <div class="form-row">

        <div class="form-group">
          <input type="text" id="firstName" [(ngModel)]="firstName" [ngClass]="{'not-empty': firstName.length}" name="firstName" (blur)="blurField(addressForm, 'firstName')" required>
          <label for="firstName">{{'FIRSTNAME' | translate}}</label>
        </div>
        <div class="form-group">
          <input type="text" id="lastName" [(ngModel)]="lastName" [ngClass]="{'not-empty': lastName.length}" name="lastName" (blur)="blurField(addressForm, 'lastName')" required>
          <label for="lastName">{{'LASTNAME' | translate}}</label>
        </div>
      </div>

      <div class="form-group" *ngIf="requireEmail">
        <input type="text" id="email" [(ngModel)]="email" [ngClass]="{'not-empty': email.length}" name="email" (blur)="blurField(addressForm, 'email')" required>
        <label for="email">{{'EMAILADDRESS' | translate}}</label>
      </div>

      <div class="form-row form-row-3-1">
        <div class="form-group">
          <input type="text" id="streetName" [(ngModel)]="streetName" [ngClass]="{'not-empty': streetName.length}" name="streetName" (blur)="blurField(addressForm, 'streetName')" required>
          <label for="streetName">{{'STREETNAME' | translate}}</label>
        </div>
        <div class="form-group">
          <input type="text" id="address" [(ngModel)]="streetNumber" [ngClass]="{'not-empty': streetNumber.length}" name="streetNumber" (blur)="blurField(addressForm, 'streetNumber')" required>
          <label for="streetNumber">{{'STREETNUMBER' | translate}}</label>
        </div>
      </div>

      <div class="form-row form-row-1-3">
        <div class="form-group">
          <input type="text" id="zip" [(ngModel)]="zip" [ngClass]="{'not-empty': zip.length}" name="zip" (blur)="blurField(addressForm, 'zip')" required>
          <label for="zip">{{'ZIP' | translate}}</label>
        </div>
        <div class="form-group">
          <input type="text" id="city" [(ngModel)]="city" [ngClass]="{'not-empty': city.length}" name="city" (blur)="blurField(addressForm, 'city')" required>
          <label for="city">{{'CITY' | translate}}</label>
        </div>
      </div>

      <button type="submit" (click)="tracking.ctaClick('pre order')" class="button big bottomless">{{'SUBMIT' | translate}}</button>
    </form>
  </div>
</div>