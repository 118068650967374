<div class="page-wrapper">
  <div class="page full-height top-page">
    <div class="page-content">
      <app-site-header></app-site-header>
      <div>
        <div class="center-content-vertically full-height">
          <div class="page-intro center">
            <h1 class="page-title">{{'COMPANYNAME' | translate}}</h1>
            <p class="page-text">
              Thurgauerstrasse 101B<br>
              8152 Glattpark (Opfikon)<br>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page no-gradient">
    <app-site-footer></app-site-footer>
  </div>
</div>
