<div class="slice full-width bigly-spacing primary" [attr.id]="sliceData.id">
  <div class="content">
    <h2 class="page-centered-title">{{sliceData.title}}</h2>
    <div class="pricing-comparison">
      <div class="monthly" appInView (enterView)="monthlyInView = true"
           [@feature]="monthlyInView ? 'inview' : 'outofview'">
        <div class="price">{{sliceData.monthlyPrice}}<sup>{{'CHFPERMONTH' | translate}}</sup></div>
        <span class="duration">{{ 'MONTHLY' | translate }}</span>
        <span class="description" [innerHTML]="sliceData.monthlyPriceText"></span>
      </div>
      <div class="splitter" appInView (enterView)="splitterInView = true">
        <div class="splitter-line" [@splitter]="splitterInView ? 'inview' : 'outofview'"></div>
        <span [@feature]="splitterInView ? 'inview' : 'outofview'">{{'OR' | translate}}</span>
      </div>
      <ng-template [ngIf]="!!sliceData.dataPrice">
        <div class="data" appInView (enterView)="dataInView = true" [@feature]="dataInView ? 'inview' : 'outofview'">
          <div class="price">{{sliceData.dataPrice}}<small>{{'CHFPERMONTH' | translate}}</small></div>
          <span class="duration">{{'DATA' | translate}}</span>
          <span class="description" [innerHTML]="sliceData.dataPriceText"></span>
        </div>
      </ng-template>
      <ng-template [ngIf]="!sliceData.dataPrice">
        <div class="daily" appInView (enterView)="dailyInView = true" [@feature]="dailyInView ? 'inview' : 'outofview'">
          <div class="price">{{sliceData.dailyPrice}}<small>{{'CHFPERDAY' | translate}}</small></div>
          <span class="duration">{{'DAILY' | translate}}</span>
          <span class="description" [innerHTML]="sliceData.dailyPriceText"></span>
        </div>
      </ng-template>
    </div>
    <div appInView (enterView)="paymentMethodsInView = true" [@feature]="paymentMethodsInView ? 'inview' : 'outofview'">

      <div class="international-option">
        <div class="horizontal-option-line"></div>
        <div class="option-name">{{sliceData.internationalHeader}}</div>
        <div class="option-desc">
          <span [innerHTML]="sliceData.internationalText"></span>
          <app-tooltip *ngIf="sliceData.tooltipText" [text]="sliceData.tooltipText">
          </app-tooltip>
        </div>
      </div>

    </div>
  </div>
</div>
