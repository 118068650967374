<div class="slice custom-content" [class]="{
  'primary': sliceData.backgroundColor == 'red', 
  'white': sliceData.backgroundColor == 'white', 
  'lighter': sliceData.backgroundColor == 'gray',
  'big-spacing': sliceData.spacing == 'default',
  'headless': sliceData.spacing == 'headless',
  'bottomless': sliceData.spacing == 'bottomless'
}" [attr.id]="sliceData.id">
  <div class="content">
    <span [innerHTML]="sliceData.content"></span>
  </div>
</div>
