import { Injectable } from '@angular/core';
import { StorageEntryConfig } from './storage-entry-config.class';
import { StorageType } from './storage-type.enum';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {}

  //TODO allow time limit for local storage

  /**
   * Saves an value to session or local storage.
   * @param conf configruation of the entry (key, session or local storage )
   * @param value the value to be stored
   */
  save(conf: StorageEntryConfig, value: string) {
    try {
      if (conf.type === StorageType.SESSION ) {
        sessionStorage.setItem(conf.key, value);
      } else {
        localStorage.setItem(conf.key, value);
      }
    } catch (error) {
      console.log('StorageServiceService -> save -> error', error);
    }
  }

  /**
   * Gets an entry from local or session storage.
   * @param conf config object: whether it's session or local storage, key used
   * @returns the stored value, or null if it does not exist
   */
  get(conf: StorageEntryConfig): string {
    if (conf.type === StorageType.SESSION) {
      return sessionStorage.getItem(conf.key);
    } else {
      return localStorage.getItem(conf.key);
    }
  }
}
