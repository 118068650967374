import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CampaignService } from './campaign.service';
import { StorageEntryConfig } from './storage-entry-config.class';
import { StorageService } from './storage.service';
import { NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  public static readonly EVENT_VIRTUAL_PAGE_VIEW = 'virtual page view';
  public static readonly EVENT_VISITOR_INTERACTION = 'gtm visitor interaction';
  public static readonly EVENT_OPTIMIZE_ACTIVATE = 'optimize.activate';

  public static readonly VI_NAME_BUTTON_INTERACTION = 'button interaction';
  public static readonly VI_NAME_FORM_TRACKING = 'form tracking';
  public static readonly VI_NAME_404 = 'page not found';
  public static readonly VI_NAME_FORM_SUBMIT = 'form submit';
  public static readonly VI_NAME_FORM_ERROR = 'form error';

  constructor(
    private storage: StorageService,
    private influencer: CampaignService
  ) {
    window.dataLayer = window.dataLayer || [];
  }

  getDownloadLink() {
    const influencerName = this.storage.get(
      StorageEntryConfig.INFLUENCER_CAMPAIGN
    );
    if (influencerName && this.influencer[influencerName]) {
      return this.influencer[influencerName].downloadLink;
    }
    return 'https://app.swype.ch';
  }

  getDownloadLinkForStore(store: 'google' | 'apple') {
    if (store === 'apple') {
      return 'https://apps.apple.com/ch/app/swype-mobile-just-better/id1479174713';
    } else if (store === 'google') {
      return 'https://play.google.com/store/apps/details?id=ch.swype';
    } else {
      return 'https://app.swype.ch';
    }
  }

  ctaClick(name: string) {
    const influencer = this.storage.get(StorageEntryConfig.INFLUENCER_CAMPAIGN);
    const trackInfluencer =
      ['order now', 'pre order'].includes(name) && influencer;
    window.dataLayer.push({
      event: TrackingService.EVENT_VISITOR_INTERACTION,
      interaction_name: TrackingService.VI_NAME_BUTTON_INTERACTION,
      button_action: trackInfluencer ? name + ' ' + influencer : name
    });
  }

  formFieldBlur(form: string, field: string) {
    window.dataLayer.push({
      event: TrackingService.EVENT_VISITOR_INTERACTION,
      interaction_name: TrackingService.VI_NAME_FORM_TRACKING,
      form_name: form,
      form_field: field
    });
  }

  pageNotFound(path) {
    window.dataLayer.push({
      event: TrackingService.EVENT_VISITOR_INTERACTION,
      interaction_name: TrackingService.VI_NAME_404,
      event_action: path,
      event_label: document.referrer || ''
    });
  }

  submitForm(formName: string, form: NgForm, error: string = '') {
    if (!form.form.valid && !error) {
      error = Object.keys(form.form.controls)
        .map(key => {
          const control = form.form.controls[key];
          const errors = control.errors ? Object.keys(control.errors) : [];
          if (errors.length) {
            return key;
          }
        })
        .filter(c => c)
        .join(',');
    }

    window.dataLayer.push({
      event: TrackingService.EVENT_VISITOR_INTERACTION,
      interaction_name: TrackingService.VI_NAME_FORM_SUBMIT,
      form_name: formName,
      form_submission: !error ? 'successful' : 'not successful'
    });

    if (error) {
      window.dataLayer.push({
        event: TrackingService.EVENT_VISITOR_INTERACTION,
        interaction_name: TrackingService.VI_NAME_FORM_ERROR,
        form_name: formName,
        form_field: error,
        form_message: ''
      });
    }
  }

  triggerVirtualPageView(event: NavigationEnd) {
    // get rid of locale
    const path = event.urlAfterRedirects.substring(3) || '/';

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: TrackingService.EVENT_VIRTUAL_PAGE_VIEW,
      page_name: path.split('#').join('/'),
      page_referrer: window.location.href || document.referrer,
      page_url: window.location.href
    });
  }

  triggerOptimizeActivateEvent() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: TrackingService.EVENT_OPTIMIZE_ACTIVATE });
  }
}
