import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { StorageService } from './storage.service';
import { StorageEntryConfig } from './storage-entry-config.class';

@Injectable()
export class ReferrerTrackingResolve implements Resolve<string> {
  constructor(private storage: StorageService) {}

  resolve(route: ActivatedRouteSnapshot): any {
    const referrer = route.queryParams['utm_campaign'];
    if (referrer) {
      this.storage.save(StorageEntryConfig.UTM_CAMPAIGN, referrer);
    }
  }
}
