import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { PricingSlice } from '../pricing-slice';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  encapsulation: ViewEncapsulation.None, //to be able to style content inserted via innerHTML
  animations: [
    trigger('feature', [
      state('inview', style({ transform: 'translateY(0)', opacity: 1 })),
      state('outofview', style({ transform: 'translateY(25%)', opacity: 0 })),
      transition('outofview => inview', animate('0.5s ease-out'))
    ]),
    trigger('splitter', [
      state(
        'inview',
        style({
          transform: `translate(-50%, -50%) rotate(${
            window.innerWidth > 700 ? '10' : '-4'
          }deg)`,
          opacity: 1
        })
      ),
      state(
        'outofview',
        style({ transform: 'translate(-50%, -50%) rotate(0)', opacity: 0 })
      ),
      transition('outofview => inview', animate('0.5s ease-out'))
    ])
  ]
})
export class PricingComponent implements OnInit {
  @Input() sliceData: PricingSlice;
  monthlyInView = false;
  dailyInView = false;
  dataInView = false;
  splitterInView = false;
  paymentMethodsInView = false;

  constructor() {}

  ngOnInit(): void {}
}
