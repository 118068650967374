import { Component, OnInit, Input } from '@angular/core';
import { HeroSlice } from '../hero-slice';

@Component({
  selector: 'app-hero-slice',
  templateUrl: './hero-slice.component.html',
  styleUrls: ['./hero-slice.component.scss']
})
export class HeroSliceComponent implements OnInit {
  @Input() sliceData: HeroSlice;

  constructor() {}

  ngOnInit(): void {}
}
